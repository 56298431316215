<template>
  <div class="top">
    <ul class="top-slides-bg-circles">
    </ul>
    <ul class="top-slides-bg-circles2">
    </ul>
    <v-container fluid>
      <div class="top-slides-bg" ref="getSlidesHeight">
        <div class="top-slides">
          <v-row>
            <v-col cols="12">
              <carousel
                :autoplay="4000"
                :wrap-around="true"
                :i18n="{
                  ariaNextSlide: '次のスライドへ',
                  ariaPreviousSlide: '前のスライドへ',
                  ariaNavigateToSlide: '{slideNumber}枚目に移動',
                  ariaGallery: 'ギャラリー',
                  itemXofY: '{currentSlide}/{slidesCount}',
                  iconArrowUp: '上向き矢印',
                  iconArrowDown: '下向き矢印',
                  iconArrowRight: '右向き矢印',
                  iconArrowLeft: '左向き矢印',
                }"
              >
                <slide v-for="(slide, h) in infos" :key="h">
                  <div
                    @mousedown="slideClickStart()"
                    @mouseup="slideClickEnd(slide.url)"
                    @touchstart="slideTapStart()"
                    @touchend="slideTapEnd(slide.url)"
                    class="top-slides-img"
                  >
                    <v-img aspect-ratio="16/9" cover :src="slide.src"></v-img>
                    <div >
                      <v-text class="top-slides-description" v-html="slide.description" />
                    </div>
                  </div>
                </slide>
                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </v-col>
          </v-row>
          <div class="py-0 py-sm-3"></div>
          <div class="d-none d-sm-flex">
            <v-row>
              <v-col cols="3" v-for="(info, j) in infos" :key="j">
                <a v-bind:href="info.url" class="top-slides-img">
                  <img :src="info.src" alt="スライド" />
                </a>
              </v-col>
            </v-row>
          </div>
        </div>
        <!--<router-link to="/M3" class="mt-4 mx-auto mb-0">＞＞ 2024春M3特設ページはこちら！ ＜＜</router-link>-->
        <div class="py-6"></div>
      </div>
      <div class="top-news-bg">
        <div class="py-2 py-sm-4"></div>
        <div class="top-news">
          <v-row class="mt-5">
            <v-col cols="12"><p class="topic">News</p></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="8">
              <v-card flat class="mx-auto">
                <v-list aria-label="最近のニュース">
                  <v-list-item v-for="(item, i) in items" :key="i">
                    <div class="d-flex flex-column flex-md-row">
                      <div class="d-flex flex-row">
                        <v-icon class="top-news-icon mr-3">{{
                          item.icon
                        }}</v-icon>
                        <v-list-item-title class="top-news-date mr-3">{{
                          item.date
                        }}</v-list-item-title>
                      </div>
                      <div class="my-2 my-md-0">
                        <v-list-item-title class="top-news-text" v-html="item.text" />
                      </div>
                    </div>
                    <v-divider class="my-1"></v-divider>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="top-news-prev py-4">
                <router-link to="/works">
                  Previous News<v-icon
                    >mdi-chevron-double-right</v-icon
                  ></router-link
                >
              </div>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
          <div class="py-6 py-sm-16"></div>
        </div>
      </div>
      <div class="top-about-bg">
        <div class="py-2 py-sm-4"></div>
        <v-row class="mt-5">
          <v-col cols="12"><p class="topic">About</p></v-col>
        </v-row>
        <v-row class="about">
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" xxl="2"></v-col>
          <v-col cols="4" sm="3" md="2" lg="2" xl="2" xxl="2">
            <v-img
              class="rounded-circle"
              aspect-ratio="1"
              v-bind:src="require('../assets/profile_icon.webp')"
            ></v-img>
          </v-col>
          <v-col cols="4" class="d-flex d-sm-none"></v-col>
          <v-col cols="1" class="d-flex d-sm-none"></v-col>
          <v-col cols="10" sm="5" md="6" lg="6" xl="6" xxl="6">
            <v-card
              flat
              shaped
              style="border: 3px solid #fdd; border-radius: 20px; color: #222"
            >
              <v-card-title class="text-h4 px-5 mt-6 mb-3"
                >相生あおは</v-card-title
              >
              <v-card-text class="text-h6 px-5 pb-0 mb-3"
                >仙台市に生息するねこ。<br />曲と旅、猫とPC、甘い物と生ハム、天体観測とマイクラが好き。</v-card-text
              >
              <div class="pb-6"><ex-link-svg /></div>
              <v-btn
                flat
                color="#f8c6c6"
                class="mb-8"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdXY0XMOeJz-aDWxpiDzTh1qhc5Abs8PrmayWnvpLwlRkE29Q/viewform?usp=sf_link"
                target="_blank"
                ><p style="color: #555">CONTACT</p></v-btn
              >
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <div class="py-6 py-sm-16"></div>
      </div>
      <div class="top-link-bg">
        <div class="py-sm-4"></div>
        <div class="top-link">
          <v-row class="mt-5">
            <v-col cols="12"><p class="topic">Link</p></v-col>
          </v-row>
          <v-row>
            <v-col
              cols="10"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              xxl="4"
              v-for="(link, k) in links"
              :key="k"
              class="top-link-stretch mx-auto"
            >
              <v-card class="links" :href="link.url" target="_blank">
                <v-img v-bind:src="link.src"></v-img>
                <v-card-title
                  class="top-link-name text-h6 my-3"
                  style="color: #222"
                  >{{ link.name }}</v-card-title
                >
                <v-card-text class="top-link-text my-3" style="color: #222">{{
                  link.text
                }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="py-12"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
import ExLinkSvg from "../components/ExLinkSVG.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

let tapCount = 0;

export default {
  components: {
    ExLinkSvg,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    model: 1,
    infos: [
      {
        url: "/",
        src: require("../assets/M3/Circlecut.webp"),
        description: "M3参加決定！<b>G-20a</b>でお待ちしております！",
      },

    /*      {
        url: "/M3",
        src: require("../assets/M3/Circlecut.webp"),
        description: "M3参加決定！",
      },*/
/*      {
        url: "/M3",
        src: require("../assets/M3/Circlecut.webp"),
        description: "M3ありがとうございました！",
      },*/
/*      {
        url: "/booth",
        src: require("../assets/booth_gn.webp"),
        description: "BOOTH開設しました！",
      },*/
      {
        url: "/booth",
        src: require("../assets/booth.webp"),
        description: "BOOTH開設しました！",
      },
/*      {
        url: "/music",
        src: require("../assets/musics/Tabiji_heno_shoukei.webp"),
        description: "最新曲『旅路への憧憬』",
      },*/
      {
        url: "https://semicolon0103.hatenablog.com/",
        src: require("../assets/blog_carousel.webp"),
        description: "相生あおはの書庫",
      },
      {
        url: "/trip",
        src: require("../assets/trips/trip_26.webp"),
        description: "週末に金沢と京都へ",
      },
    ],
    items: [
      {
        icon: "mdi-bullhorn",
        date: "24/07/29",
        text: "秋M3参加決定！\n<b>G-20a</b>でお待ちしております！",
      },
      {
        icon: "mdi-trophy",
        date: "23/07/02",
        text: "『雨にして人を外れ』がsteamで\n1万ダウンロードを達成しました",
      },
      {
        icon: "mdi-newspaper-variant",
        date: "22/10/01",
        text: "Game*Spark,YahooNewsにて\n『雨にして人を外れ』が\nメディア掲載されました",
      },
    ],
    links: [
      {
        url: "https://d10rama.com/",
        src: require("../assets/d10rama.webp"),
        name: "D10RAMA(ジオラマ)",
        text: "私がBGM(音響)を担当している\nビジュアルノベル制作サークルです。",
      },
      {
        url: "https://micomprocedure.com/",
        src: require("../assets/micomprocedure.webp"),
        name: "東北大学マイコンプロシージャ",
        text: "現役時代は部長をしていました。\nゲーム制作サークルです。",
      },
      {
        url: "https://tohokuotoge.wixsite.com/tonevo",
        src: require("../assets/tonevo.webp"),
        name: "東北大学音ゲーサークル\nとんえぼ",
        text: "私が創作班で曲と譜面(KSM)を担当していた\n音ゲーサークルです。",
      },
    ],
  }),
  methods: {
    slideClickStart: function () {
      tapCount = 1;
      // 100ミリ秒以内にマウスが離されればタップと判定
      setTimeout(function () {
        tapCount = 0;
      }, 100);
    },
    slideClickEnd: function (url) {
      if (tapCount == 1) {
        if (url.includes("https")) {
          window.location.href = url;
        } else {
          this.$router.push(url);
        }
        tapCount = 0;
      }
    },
    slideTapStart: function () {
      tapCount = 1;
      // 100ミリ秒以内に指が離されればタップと判定
      setTimeout(function () {
        tapCount = 0;
      }, 100);
    },
    slideTapEnd: function (url) {
      if (tapCount == 1) {
        if (url.includes("https")) {
          window.location.href = url;
        } else {
          this.$router.push(url);
        }
        tapCount = 0;
      }
    },
  },
};
</script>

<style scoped>
.top-slides-bg-circles {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f8c6c6;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}
.top-slides-bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}
.top-slides-bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.top-slides-bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
.top-slides-bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.top-slides-bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.top-slides-bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}
.top-slides-bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.top-slides-bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.top-slides-bg-circles2 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-slides-bg-circles2 li {
  position: absolute;
  overflow: hidden;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #adb9de;
  opacity: 0;
  animation: animate 25s linear infinite;
}
.top-slides-bg-circles2 li:nth-child(1) {
  left: 80%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
}
.top-slides-bg-circles2 li:nth-child(2) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 14s;
}
.top-slides-bg-circles2 li:nth-child(3) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-delay: 16s;
}
.top-slides-bg-circles2 li:nth-child(4) {
  left: 15%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.top-slides-bg-circles2 li:nth-child(5) {
  left: 40%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}
@keyframes animate {
  0% {
    transform: translateY(1000px) rotate(0deg) scaleX(1);
    opacity: 0.4;
    border-radius: 20%;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 40%;
  }
}
</style>
